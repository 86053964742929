import React from "react"
import { Router, Match } from "@reach/router"
import { login, logout, isAuthenticated, getProfile, getTokens } from "../utils/auth"
import { Link } from "gatsby"

const MainMenuLabel = ({ to, children }) => (
  <Match path={`${to}/*`}>
    {props => (
      <li className={props.match ? 'is-active' : ''}>
        <Link to={to}>{children}</Link>
      </li>
    )}
  </Match>
)

const Home = ({ user, tokens }) => {
  return (
    <>
      <p>Hi, {user.name ? user.name : "friend"}!</p>
      <p>{JSON.stringify(user)}</p>
      <p>{JSON.stringify(tokens)}</p>
    </>
  )
}
const Settings = () => <p>Settings</p>
const Billing = () => <p>Billing</p>

export default () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()
  const tokens = getTokens()

  return (
    <>
      <div id="sidebar">
        <div id="mainTitle">Wormhole</div>
        <ul id="mainMenu">
          <MainMenuLabel to="/">Home</MainMenuLabel>
          <MainMenuLabel to="/settings">Settings</MainMenuLabel>
          <MainMenuLabel to="/billing">Billing</MainMenuLabel>
          {/*<a
            href="#logout"
            onClick={e => {
              logout()
              e.preventDefault()
            }}
          >
            Log Out
          </a>*/}
        </ul>
      </div>
      <Router id="main">
        <Home path="/" user={user} tokens={tokens} />
        <Settings path="/settings" />
        <Billing path="/billing" />
      </Router>
    </>
  )
}
